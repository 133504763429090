<template>
  <div class="card mb-3 shadow-sm">
    <span
      class="card-mark"
      :class="project.complete ? 'card-mark-success' : 'card-mark-danger'"
    ></span>
    <div class="card-body pe-3">
      <div class="d-flex justify-content-between align-items-start">
        <div>
          <a
            @click="showDetails = !showDetails"
            href="#"
            class="card-title text-dark"
            :class="{ open: showDetails }"
            >{{ project.title }}</a
          >
        </div>
        <div
          class="
            card-action
            d-flex
            justify-content-around
            align-items-center
            mt-1
          "
        >
          <router-link :to="{ name: 'editProject', params: { id: project.id } }"
            ><i class="bi bi-pen mb-0" title="edit"></i
          ></router-link>
          <a
            @click="deleteProject"
            href="#"
            class="bi bi-trash mb-0"
            title="remove"
            data-bs-toggle="modal"
            data-bs-target="#projectModal"
          ></a>
          <a
            @click="toggleComplete"
            href="#"
            class="bi bi-check2-circle bi-adjust mb-0"
            :class="{ 'text-success': project.complete }"
            title="complete"
          ></a>
        </div>
      </div>
      <div
        v-if="showDetails"
        class="fw-light text-fade mt-4 mb-0"
        v-html="detailsHtml"
      ></div>
    </div>
  </div>
</template>

<script>
import marked from 'marked'
import { useToast } from 'vue-toastification'

export default {
  props: ['project'],
  inject: ['store'],
  data() {
    return {
      showDetails: false,
      uri: `/api/v1/600/projects/${this.project.id}`
    }
  },
  methods: {
    deleteProject() {
      this.$emit('delete', this.project.id)
    },
    toggleComplete() {
      const toast = useToast()

      let resOk = 0
      // alert
      const successMsg = !this.project.complete
        ? 'Project complete!'
        : 'Project ongoing!'
      fetch(this.uri, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.store.state.user.accessToken}`
        },
        body: JSON.stringify({ complete: !this.project.complete })
      })
        .then((res) => {
          resOk = res.ok
          return res.json()
        })
        .then((data) => {
          // check error
          if (!resOk) throw new Error(data)

          this.$emit('complete', this.project.id)

          toast.success(successMsg, {
            id: 'projectStatus'
          })
        })
        .catch((err) => {
          console.log(err.message)

          // logout the page if the jwt expired
          if (err.message === 'jwt expired') {
            // alert
            toast.error('Session expired. Try to login again!', {
              id: 'projectNotAdded'
            })

            this.error = 'Session expired. Try to login again!'
            // remove userData from localStorage
            // localStorage.setItem('userData', 'null')

            // set user null in store
            this.store.actions.setUser(null)

            setTimeout(() => {
              // navigate to login page
              this.$router.push('/login')
            }, 2000)
          } else {
            // alert
            toast.error(err.message, {
              id: 'projectNotRemoved'
            })
          }
        })
    }
  },
  computed: {
    detailsHtml() {
      return marked(this.project.details)
    }
  }
}
</script>