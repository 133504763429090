<template>
  <div class="row">
    <div class="col-lg-8 col-xl-7 mx-auto">
      <template v-if="loading">
        <FilterNav @filterChange="filter = $event" :filter="filter" />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
      </template>
      <template v-else-if="error"
        ><p class="lead text-center">{{ error }}</p></template
      >
      <template v-else-if="projects.length">
        <FilterNav @filterChange="filter = $event" :filter="filter" />
        <div v-for="project in filteredProjects" :key="project.id">
          <Project
            :project="project"
            @complete="handleComplete"
            @delete="handleDelete"
          />
        </div>
      </template>
      <template v-else-if="projects">
        <p class="lead text-center">No projects found!</p>
      </template>
    </div>
  </div>
  <ProjectModal
    title="Remove Project"
    message="Are you sure you wish to remove this project?"
    :action="handleConfimDelete"
  />
</template>

<script>
import Project from '../components/Project'
import ProjectModal from '../components/ProjectModal'
import FilterNav from '../components/FilterNav'
import SkeletonItem from '../components/skeleton/SkeletonItem'
import { useToast } from 'vue-toastification'

export default {
  name: 'home',
  components: {
    Project,
    ProjectModal,
    FilterNav,
    SkeletonItem
  },
  inject: ['store'],
  data() {
    return {
      uri: '/api/v1/600/projects',
      projects: [],
      removedProjectId: null,
      filter: 'all',
      loading: false,
      error: ''
    }
  },
  async mounted() {
    this.loading = true
    const toast = useToast()

    // simulate delay
    await new Promise((resolve) => {
      setTimeout(resolve, 800)
    })

    let resOk = 0
    fetch(`${this.uri}/?userId=${this.store.state.user.user.id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.store.state.user.accessToken}`
      }
    })
      .then((res) => {
        resOk = res.ok
        return res.json()
      })
      .then((data) => {
        // check error
        if (!resOk) throw new Error(data)

        this.projects = data
        this.loading = false
      })
      .catch((err) => {
        console.log(err.message)
        this.error = err.message
        this.loading = false

        // logout the page if the jwt expired
        if (err.message === 'jwt expired') {
          this.error = 'Session expired. Try to login again!'
          // remove userData from localStorage
          // localStorage.setItem('userData', 'null')

          // set user null in store
          this.store.actions.setUser(null)

          // alert
          toast.error('Session expired. Try to login again!', {
            id: 'projectNotFetched'
          })

          setTimeout(() => {
            // navigate to login page
            this.$router.push('/login')
          }, 2000)
        }
      })
  },
  methods: {
    handleDelete(id) {
      this.removedProjectId = id
    },
    handleConfimDelete() {
      const toast = useToast()

      let resOk = 0
      fetch(`${this.uri}/${this.removedProjectId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.store.state.user.accessToken}`
        }
      })
        .then((res) => {
          resOk = res.ok
          return res.json()
        })
        .then((data) => {
          // check error
          if (!resOk) throw new Error(data)

          this.projects = this.projects.filter(
            (project) => project.id !== this.removedProjectId
          )

          // alert
          toast.success('Project has been successfully removed!', {
            id: 'projectRemoved'
          })
        })
        .catch((err) => {
          console.log(err.message)

          // logout the page if the jwt expired
          if (err.message === 'jwt expired') {
            // alert
            toast.error('Session expired. Try to login again!', {
              id: 'projectNotAdded'
            })

            this.error = 'Session expired. Try to login again!'
            // remove userData from localStorage
            // localStorage.setItem('userData', 'null')

            // set user null in store
            this.store.actions.setUser(null)

            setTimeout(() => {
              // navigate to login page
              this.$router.push('/login')
            }, 2000)
          } else {
            // alert
            toast.error(err.message, {
              id: 'projectNotRemoved'
            })
          }
        })
        .finally(() => {
          document.getElementsByClassName('btn-close')[0].click()
        })
    },
    handleComplete(id) {
      let p = this.projects.find((project) => project.id === id)
      p.complete = !p.complete
    }
  },
  computed: {
    filteredProjects() {
      if (this.filter === 'completed') {
        return this.projects.filter((project) => project.complete)
      }
      if (this.filter === 'ongoing') {
        return this.projects.filter((project) => !project.complete)
      }
      return this.projects
    }
  }
}
</script>
