<template>
  <div class="skeleton-wrapper">
    <div class="card mb-3">
      <div class="card-body pe-3 shadow-sm">
        <div class="d-flex justify-content-between align-items-center">
          <div class="card-title">
            <SkeletonElement type="title" />
          </div>
          <div
            class="card-action d-flex justify-content-around align-items-center"
          >
            <SkeletonElement type="action" />
            <SkeletonElement type="action" />
            <SkeletonElement type="action" />
          </div>
        </div>
      </div>
    </div>
    <Shimmer />
  </div>
</template>

<script>
import Shimmer from './Shimmer.vue'
import SkeletonElement from './SkeletonElement.vue'

export default {
  components: { Shimmer, SkeletonElement }
}
</script>

<style scoped>
.skeleton-wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background-color: transparent;
}
.card-title::before {
  content: none;
}
</style>
