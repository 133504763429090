<template>
  <footer class="text-center small text-fade fw-light mt-5 pt-3 mb-5">
    <p class="mb-4">Youcef Developer &copy; 2022</p>
    <p class="">Technologies</p>
    <div>
      <span class="badge bg-primary">MEVN</span>
      <span class="badge bg-primary">MongoDB</span>
      <span class="badge bg-primary">Express</span>
      <span class="badge bg-primary">Vue.js</span>
      <span class="badge bg-primary">Node</span>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>
.badge {
  font-weight: 300;
  border-radius: 20px;
  margin: 2px 2px;
  padding: 3px 8px 3px 8px;
  line-height: 14px;
}
</style>