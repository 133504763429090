import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home'
import AddProject from '../views/AddProject'
import EditProject from '../views/EditProject'
import Login from '../views/Login'
import Register from '../views/Register'

// store
import store from '@/store'

const requireAuth = (to, from, next) => {
  if (!store.state.user) {
    // redirect to login page
    next({ name: 'login' })
  } else {
    // go next
    next()
  }
}

const isLoggedIn = (to, from, next) => {
  if (store.state.user) {
    // redirect to homepage
    next({ name: 'home' })
  } else {
    // go next
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/add',
    name: 'addProject',
    component: AddProject,
    beforeEnter: requireAuth
  },
  {
    path: '/edit/:id',
    name: 'editProject',
    component: EditProject,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: isLoggedIn
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: isLoggedIn
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
})

export default router
