import { reactive, readonly } from 'vue'

const state = reactive({
  user: JSON.parse(localStorage.getItem('userData')) || null
})

export default {
  state: readonly(state),
  actions: {
    setUser(user) {
      state.user = user
    }
  }
}
