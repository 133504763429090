<template>
  <nav class="nav nav-sort mb-2">
    <a
      @click="updateFilter('all')"
      class="nav-link"
      :class="{ active: filter === 'all' }"
      aria-current="page"
      href="#"
      >VIEW ALL</a
    >
    <a
      @click="updateFilter('completed')"
      class="nav-link"
      :class="{ active: filter === 'completed' }"
      href="#"
      >COMPLETED</a
    >
    <a
      @click="updateFilter('ongoing')"
      class="nav-link"
      :class="{ active: filter === 'ongoing' }"
      href="#"
      >ONGOING</a
    >
  </nav>
</template>

<script>
export default {
  props: ['filter'],
  methods: {
    updateFilter(filterBy) {
      this.$emit('filterChange', filterBy)
    }
  }
}
</script>