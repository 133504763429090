<template>
  <div class="row">
    <div class="col-lg-8 col-xl-7 mx-auto">
      <div class="card">
        <div class="card-header fw-light text-fade">Edit Project #{{ id }}</div>
        <div class="card-body">
          <form novalidate @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label for="title" class="form-label text-muted text-uppercase"
                >Title</label
              >
              <input
                type="text"
                class="form-control form-control-lg"
                :class="{ 'is-invalid': this.errorTitle }"
                id="title"
                name="title"
                placeholder=""
                v-model="title"
              />
              <div
                class="invalid-feedback fw-light d-block"
                v-if="this.errorTitle"
              >
                {{ this.errorTitle }}
              </div>
            </div>
            <div class="mb-0">
              <label for="details" class="form-label text-muted text-uppercase"
                >Details</label
              >
              <vue-simplemde
                preview-class="markdown-body"
                :class="{ 'is-invalid': this.errorDetails }"
                v-model="details"
              />
              <div
                class="invalid-feedback fw-light d-block position-relative"
                v-if="this.errorDetails"
                style="top: -30px"
              >
                {{ this.errorDetails }}
              </div>
            </div>
            <button class="btn btn-lg btn-primary mt-2 mb-3">
              Update Project
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimplemde from 'vue-simplemde'
import { useToast } from 'vue-toastification'

export default {
  name: 'editProject',
  props: ['id'],
  components: { VueSimplemde },
  inject: ['store'],
  data() {
    return {
      uri: `/api/v1/600/projects/${this.id}`,
      title: '',
      details: '',
      errorTitle: '',
      errorDetails: ''
    }
  },
  mounted() {
    const toast = useToast()

    let resOk = 0
    fetch(this.uri, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.store.state.user.accessToken}`
      }
    })
      .then((res) => {
        resOk = res.ok
        return res.json()
      })
      .then((data) => {
        // check error
        if (!resOk) throw new Error(data)

        this.title = data.title
        this.details = data.details
      })
      .catch((err) => {
        console.log(err.message)

        // logout the page if the jwt expired
        if (err.message === 'jwt expired') {
          // alert
          toast.error('Session expired. Try to login again!', {
            id: 'projectNotAdded'
          })

          this.error = 'Session expired. Try to login again!'
          // remove userData from localStorage
          // localStorage.setItem('userData', 'null')

          // set user null in store
          this.store.actions.setUser(null)

          setTimeout(() => {
            // navigate to login page
            this.$router.push('/login')
          }, 2000)
        } else {
          // alert
          toast.error(err.message, {
            id: 'projectNotAdded'
          })
        }
      })
  },
  methods: {
    handleSubmit() {
      this.validate(this.title, this.details)

      if (!this.errorTitle && !this.errorDetails) {
        const updatedProject = {
          title: this.title.trim(),
          details: this.details.trim()
        }
        const toast = useToast()

        let resOk = 0
        fetch(this.uri, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.store.state.user.accessToken}`
          },
          body: JSON.stringify(updatedProject)
        })
          .then((res) => {
            resOk = res.ok
            return res.json()
          })
          .then((data) => {
            // check error
            if (!resOk) throw new Error(data)

            // clear the form
            this.title = ''
            this.details = ''

            // navigate
            this.$router.push('/')

            // alert
            toast.success('Project has been successfully updated!', {
              id: 'projectUpdated'
            })
          })
          .catch((err) => {
            console.log(err.message)

            // logout the page if the jwt expired
            if (err.message === 'jwt expired') {
              // alert
              toast.error('Session expired. Try to login again!', {
                id: 'projectNotEdited'
              })

              this.error = 'Session expired. Try to login again!'
              // remove userData from localStorage
              // localStorage.setItem('userData', 'null')

              // set user null in store
              this.store.actions.setUser(null)

              setTimeout(() => {
                // navigate to login page
                this.$router.push('/login')
              }, 2000)
            } else {
              // alert
              toast.error(err.message, {
                id: 'projectNotEdited'
              })
            }
          })
      }
    },
    validate(title, details) {
      title.trim() === ''
        ? (this.errorTitle = 'Title is required')
        : (this.errorTitle = '')
      details.trim() === ''
        ? (this.errorDetails = 'Details is required')
        : (this.errorDetails = '')
    }
  }
}
</script>