import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// store
import store from '@/store'

const options = {
  position: 'top-center',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: {
    enter: 'fade-enter-active',
    leave: 'Vue-Toastification__fade-leave-active',
    move: 'fade-move'
  }
}

createApp(App)
  .provide('store', store)
  .use(Toast, options)
  .use(router)
  .mount('#app')
