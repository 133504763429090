<template>
  <ul class="nav nav-pills justify-content-center mb-5">
    <li class="nav-item">
      <router-link
        :to="{ name: 'home' }"
        class="nav-link text-uppercase"
        aria-current="page"
        >Projects</router-link
      >
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'addProject' }" class="nav-link text-uppercase"
        >Add New Project</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {}
</script>