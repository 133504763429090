<template>
  <div class="row">
    <div class="col-lg-7 col-xl-5 mx-auto">
      <div class="card">
        <div class="card-header">Login</div>
        <div class="card-body">
          <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label
              ><input
                type="email"
                class="form-control form-control-lg"
                id="email"
                name="email"
                v-model="formValues.email"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  id="password"
                  name="password"
                  v-model="formValues.password"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                /><label
                  class="form-check-label position-relative"
                  for="rememberMe"
                  style="top: 1px"
                  >Remember me</label
                >
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-outline-primary btn-lg mt-2 mb-3"
            >
              Login
            </button>
          </form>
        </div>
      </div>
      <hr class="mt-4" />
      <p class="fw-light ms-1">
        Need an account?
        <router-link to="/register" class="dotted">Register</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

import { togglePassword } from '../utils'

export default {
  name: 'login',
  inject: ['store'],
  data() {
    return {
      uri: '/api/v1/login',
      formValues: { email: '', password: '' },
      error: null
    }
  },
  methods: {
    handleShowHidePassword(e) {
      const passwordField =
        e.currentTarget.parentElement.getElementsByTagName('input')[0]
      const eyeIcon = e.currentTarget
      togglePassword(passwordField, eyeIcon)
    },
    handleSubmit() {
      const toast = useToast()
      const user = {
        email: this.formValues.email,
        password: this.formValues.password
      }

      let resOk = 0
      fetch(this.uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then((res) => {
          resOk = res.ok
          return res.json()
        })
        .then((data) => {
          // check error
          if (!resOk) throw new Error(data)

          // clear form
          this.formValues = {
            email: '',
            password: ''
          }

          // save user data in localStorage
          localStorage.setItem('userData', JSON.stringify(data))

          // TODO: update store
          this.store.actions.setUser(data)

          // navigate
          this.$router.push('/')

          // alert
          toast.success('You have been successfully logged in!', {
            id: 'userLoggedIn'
          })
        })
        .catch((err) => {
          // alert
          toast.error(err.message, {
            id: 'userNotLoggedIn'
          })
        })
    }
  }
}
</script>