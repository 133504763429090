<template>
  <div class="skeleton" :class="type"></div>
</template>

<script>
export default {
  props: ['type']
}
</script>

<style scoped>
.skeleton {
  background-color: #ddd;
  border-radius: 4px;
}
.skeleton.title {
  display: inline-block;
  width: 150px;
  height: 22px;
  margin-top: 7.6px;
  opacity: 0.8;
  border-radius: 0.8rem;
}
.skeleton.action {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 2px;
  opacity: 0.7;
  border-radius: 50%;
}
@media (min-width: 576px) {
  .skeleton.title {
    width: 200px;
  }
}
</style>