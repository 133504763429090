<template>
  <div class="row">
    <div class="col-lg-7 col-xl-5 mx-auto">
      <div class="card">
        <div class="card-header">Register</div>
        <div class="card-body">
          <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">
              <label for="name" class="form-label">Name</label
              ><input
                type="text"
                class="form-control form-control-lg"
                id="name"
                name="name"
                v-model="formValues.name"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label"
                >Email <span class="required">(required)</span></label
              ><input
                type="email"
                class="form-control form-control-lg"
                :class="{ 'is-invalid': formErrors.email }"
                id="email"
                name="email"
                v-model="formValues.email"
              />
              <div
                class="invalid-feedback fw-light d-block"
                v-if="formErrors.email"
              >
                {{ formErrors.email }}
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label"
                >Password <span class="required">(required)</span></label
              >
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': formErrors.password }"
                  id="password"
                  name="password"
                  v-model="formValues.password"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
                <div
                  class="invalid-feedback fw-light d-block"
                  v-if="formErrors.password"
                >
                  {{ formErrors.password }}
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="confirmPassword" class="form-label"
                >Confirm Password
                <span class="required">(required)</span></label
              >
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': formErrors.confirmPassword }"
                  id="confirmPassword"
                  name="confirmPassword"
                  v-model="formValues.confirmPassword"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
                <div
                  class="invalid-feedback fw-light d-block"
                  v-if="formErrors.confirmPassword"
                >
                  {{ formErrors.confirmPassword }}
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-outline-primary btn-lg mt-2 mb-3"
            >
              Register
            </button>
          </form>
        </div>
      </div>
      <hr class="mt-4" />
      <p class="fw-light ms-1">
        Already have an account?
        <router-link to="/login" class="dotted">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

import { togglePassword } from '../utils'

export default {
  name: 'register',
  inject: ['store'],
  data() {
    return {
      uri: '/api/v1/register',
      formValues: { name: '', email: '', password: '', confirmPassword: '' },
      formErrors: {},
      error: null
    }
  },
  methods: {
    handleShowHidePassword(e) {
      const passwordField =
        e.currentTarget.parentElement.getElementsByTagName('input')[0]
      const eyeIcon = e.currentTarget
      togglePassword(passwordField, eyeIcon)
    },
    validateForm(formValues) {
      const errors = {}
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

      if (!formValues.email.trim()) {
        errors.email = 'Email is required'
      } else if (!regex.test(formValues.email.trim())) {
        errors.email = 'Email is not valid'
      }

      if (!formValues.password) {
        errors.password = 'Password is required'
      } else if (formValues.password.length < 6) {
        errors.password = 'Password must be more than 6 characters'
      } else if (formValues.password.length > 10) {
        errors.password = 'Password cannot exceed more than 10 characters'
      }

      if (formValues.confirmPassword !== formValues.password)
        errors.confirmPassword = 'Passwords not match'

      return errors
    },
    handleSubmit() {
      this.formErrors = { ...this.validateForm(this.formValues) }
      if (Object.keys(this.formErrors).length === 0) {
        const toast = useToast()
        const newUser = {
          name: this.formValues.name,
          email: this.formValues.email,
          password: this.formValues.password
        }

        fetch(this.uri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newUser)
        })
          .then((res) => res.json())
          .then((data) => {
            // clear form
            this.formValues = {
              name: '',
              email: '',
              password: '',
              confirmPassword: ''
            }

            // save user data in localStorage
            localStorage.setItem('userData', JSON.stringify(data))

            // TODO: update store
            this.store.actions.setUser(data)

            // navigate
            this.$router.push('/')

            // alert
            toast.success('You have been successfully registered!', {
              id: 'userRegistered'
            })
          })
          .catch((err) => {
            // alert
            toast.error(err.message, {
              id: 'userNotRegistered'
            })
          })
      }
    }
  }
}
</script>