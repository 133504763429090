<template>
  <div class="container-vertical">
    <div class="container-vertical-content">
      <Header />
      <div class="container">
        <!-- <Navbar /> -->
        <router-view />
      </div>
    </div>
  </div>
  <Disclaimer />
</template>

<script>
import Header from './components/Header'
import Navbar from './components/Navbar'
import Disclaimer from './components/Disclaimer'

export default {
  components: {
    Navbar,
    Header,
    Disclaimer
  }
}
</script>

<style>
body {
  background: #e9ecef;
}
@import '~simplemde/dist/simplemde.min.css';
</style>
